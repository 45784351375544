import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { RadioButton } from "primereact/radiobutton";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { BaseApiServiceImpl } from "../app_utils/api/BaseApiServiceImpl";
import { MessageUtils } from "../app_utils/utils/MessageUtils";
import { PhoneNumber } from "libphonenumber-js";
import { isEmpty } from "../app_utils/utils/Utils";

const SendSMS: React.FC = () => {
  const [message, setMessage] = useState("");
  const [recipient, setRecipient] = useState("");
  const [smsType, setSmsType] = useState<"Form" | "bulk">("Form");
  const [isPreviewDialogVisible, setPreviewDialogVisible] = useState(false);
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const toast = useRef<Toast>(null);
  const validateForm = () => {
    let isFormValid: boolean = true;
    return isFormValid;
  };

  const clearForm = () => {
    setCsvFile(null);
    setMessage("");
    setRecipient("");
    toast.current?.show({
      severity: "success",
      summary: "Success",
      detail: `SMS sent successfully as ${smsType}.`,
    });
  };
  /**
   * This submits a save user request to the backoffice
   */
  const doSendSMS = () => {
    let data: any = {
      apiKey: null,
      messages: [
        {
          message: message,
          phoneNumbers: recipient.split(/[\n,]+/).map((item: any) => item.trim()),
        },
      ],
    };

    if (validateForm()) {
      setIsSaving(true);
      new BaseApiServiceImpl("/api/v1/sms/ui")
        .postRequestWithJsonResponse(data)
        .then(async (response) => {
          setIsSaving(false);
          clearForm();
        })
        .catch((error) => {
          setIsSaving(false);
          MessageUtils.showErrorMessage(message, error.message);
        });
    }
  };
  const handleSendSms = () => {
    if (smsType === "bulk" && !csvFile) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Please upload a CSV file for bulk SMS.",
      });
      return;
    }
    if (smsType === "Form" && isEmpty(recipient)) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Please enter recipient numbers.",
      });
      return;
    }
    doSendSMS();
  };

  return (
    <div className="p-m-4 card ">
      <h4 className="p-text-center">Send SMS</h4>
      <div className="grid ">
        <div className="col-12 md:col-8 grid ">
          <div className="col-12 m-1">
            <RadioButton
              disabled={isSaving}
              inputId="Form"
              value="Form"
              onChange={(e) => setSmsType(e.value)}
              checked={smsType === "Form"}
            />
            <label htmlFor="Form" className="ml-2">
              Direct Form
            </label>
            <RadioButton
              disabled={isSaving}
              inputId="bulk"
              value="bulk"
              onChange={(e) => setSmsType(e.value)}
              checked={smsType === "bulk"}
              className="ml-4"
            />
            <label htmlFor="bulk" className="ml-2">
              File Upload (csv)
            </label>
          </div>

          {smsType === "Form" && (
            <div className="col-12 ">
              <label htmlFor="recipient" className="p-d-block w-full">
                Recipient Phone Number (s)
              </label>
              <InputTextarea
                id="recipient"
                value={recipient}
                onChange={(e) => setRecipient(e.target.value)}
                placeholder="Enter phone number e.g 256755170341 or multiple Phone numbers e.g 256755170341,254787375269"
                className="w-full mt-1"
                rows={3}
                disabled={isSaving}
              />
            </div>
          )}

          <div className="col-12  p-mb-3">
            <label htmlFor="message" className="w-full p-d-block">
              Message
            </label>
            <InputTextarea
              id="message"
              value={message}
              onChange={(e: any) => setMessage(e.target.value)}
              placeholder="Enter your message here"
              className="w-full mt-1"
              rows={5}
              disabled={isSaving}
            />
          </div>

          {smsType === "bulk" && (
            <div className="col-12 py-3">
              <FileUpload
                disabled={isSaving}
                name="csv"
                mode="basic"
                accept=".csv"
                customUpload
                auto
                uploadHandler={(e) => setCsvFile(e.files[0])}
                chooseLabel="Select CSV to upload"
                className="p-mt-2"
              />
            </div>
          )}
          <div className="col-12 md:col-4 ml-1  grid">
            <Button
              loading={isSaving}
              label="Preview"
              icon="pi pi-eye"
              className="col-5 mr-1 p-button-outlined p-button-primary"
              onClick={() => setPreviewDialogVisible(true)}
            />

            <Button
              loading={isSaving}
              label="Send Now"
              icon="pi pi-send"
              className="col-6 ml-1 p-button-success"
              onClick={handleSendSms}
            />
          </div>
        </div>
        <div className="col-12 md:col-4">
          {/* Right Section for Guidance */}
          <div title="Guidance and Manual">
            <h3>Guidance and Manual</h3>
            <p>
              <strong>Step 1:</strong> Choose the SMS type - Form or File.
            </p>
            <p>
              <strong>Step 2:</strong> Enter the recipient's phone number if sending a Form SMS or
              multiple numbers separated with either a comma(,) or a new line.
            </p>
            <p>
              <strong>Step 3:</strong> Enter your message content.
            </p>
            <p>
              <strong>Step 4:</strong> Click "Preview" to review the message before sending.
            </p>
            <p>
              <strong>Step 5:</strong> Send the SMS and check for confirmation.
            </p>
          </div>
        </div>
      </div>
      <Toast ref={toast} />
      <Dialog
        header="SMS Preview"
        visible={isPreviewDialogVisible}
        style={{ width: "50vw" }}
        onHide={() => setPreviewDialogVisible(false)}
      >
        <p>
          <strong>Type:</strong> {smsType === "Form" ? "Form SMS" : "Bulk SMS"}
        </p>
        {smsType === "Form" && (
          <p>
            <strong>Recipient:</strong> {recipient}
          </p>
        )}
        <p>
          <strong>Message:</strong> {message}
        </p>
        {smsType === "bulk" && (
          <p>
            <strong>CSV File:</strong> {csvFile ? csvFile.name : "No file selected"}
          </p>
        )}
      </Dialog>
    </div>
  );
};

export default SendSMS;
